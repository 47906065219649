import axios from '@axios'

export const getAdminsList = async (params: any): Promise<any> => {
  const res = await axios.get('/auth/admins', { params })
  return res
}

export const getAdmin = async (id: string): Promise<any> => {
  const res = await axios.get(`/auth/admins/${id}`)
  return res
}

export const updateAdmin = async (params: any): Promise<any> => {
  const res = await axios.put(`/auth/admins/${params.id}`, params)
  return res
}

export const exportAdmins = async (params: any): Promise<any> => {
  const res = await axios.get('/auth/admins/export', { params })
  return res
}
