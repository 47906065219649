import Vue from 'vue'
// Notification
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { createModule } from 'vuexok'
import router from '@/router'
import store from '@/store'
import { getAdminsList, exportAdmins } from '@/api/admins'
import { AdminsState, IAdminsListRequest } from './types'
import downloadFile from '@/helpers/downloadExportFile'
import { DEFAULT_PER_PAGE, PER_PAGE_OPTIONS } from '@/constants/base'
import setPerPage from '@/helpers/setPerPage'

// eslint-disable-next-line import/prefer-default-export
export const appAdmins = createModule(
  'appAdmins',
  {
    namespaced: true,
    state: {
      tableColumns: [
        { key: 'name', label: 'name', sortable: true },
        { key: 'role_name', label: 'role', sortable: true },
        { key: 'last_login', label: 'last login', sortable: true },
        { key: 'status', sortable: true },
        { key: 'actions' },
      ],
      perPage: Number(localStorage.getItem('usersPerPage')) || DEFAULT_PER_PAGE,
      totalItems: 0,
      currentPage: 1,
      perPageOptions: PER_PAGE_OPTIONS,
      searchQuery: '',
      filterDataStatus: '',
      sortBy: 'created_at',
      isSortDirDesc: true,
      filterStatusOptions: ['Active', 'Inactive'],
      isLoading: false,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
    } as AdminsState,
    getters: {
      getTableColumns: state => state.tableColumns,
      getPerPage: state => state.perPage,
      getTotalItems: state => state.totalItems,
      getCurrentPage: state => state.currentPage,
      getPerPageOptions: state => state.perPageOptions,
      getSearchQuery: state => state.searchQuery,
      getFilterDataStatus: state => state.filterDataStatus,
      getSortBy: state => state.sortBy,
      getIsSortDirDesc: state => state.isSortDirDesc,
      getFilterStatusOptions: state => state.filterStatusOptions,
      getIsLoading: state => state.isLoading,
      getDataMeta: state => state.dataMeta,
    },
    mutations: {
      SET_PER_PAGE(state, val: number) {
        localStorage.setItem('adminsPerPage', val.toString())
        state.perPage = val
      },
      SET_TOTAL_ITEMS(state, val: number) {
        state.totalItems = val
      },
      SET_CURRENT_PAGE(state, val: number) {
        state.currentPage = val
      },
      SET_SEARCH_QUERY(state, val: string) {
        state.searchQuery = val
      },
      SET_FILTER_DATA_STATUS(state, val: string) {
        state.filterDataStatus = val
      },
      SET_SORT_BY(state, val: string) {
        state.sortBy = val
      },
      SET_IS_SORT_DESC(state, val: boolean) {
        state.isSortDirDesc = val
      },
      SET_IS_LOADING(state, val: boolean) {
        state.isLoading = val
      },
      SET_DATA_META(state, val) {
        state.dataMeta = val
      },
      CLEAR_FILTERS(state) {
        state.filterDataStatus = ''
      },
      SET_FILTER_PARAMS(state) {
        const query = { ...router.currentRoute.query }
        const defaultPerPage = localStorage.getItem('adminsPerPage')
        state.currentPage = Number(query.currentPage) || 1
        state.perPage = setPerPage(query.perPage, defaultPerPage, DEFAULT_PER_PAGE)
        state.searchQuery = query.searchQuery || ''
        state.filterDataStatus = query.filterDataStatus || ''
      },
    },
    actions: {
      async fetchAdminsList({ state }) {
        try {
          const queryParams: IAdminsListRequest = {
            perPage: state.perPage,
            page: state.currentPage || 1,
            sortBy: state.sortBy,
            sortDesc: state.isSortDirDesc,
            useSchoolFilter: 1,
          }
          if (state.searchQuery) queryParams.name = state.searchQuery
          if (state.filterDataStatus) queryParams.status = state.filterDataStatus
          const response:any = await getAdminsList(queryParams)

          const {
            total, from, to,
          } = response.data.meta

          const dataMeta = {
            from,
            to,
            of: total,
          }

          appAdmins.mutations.SET_TOTAL_ITEMS(total)
          appAdmins.mutations.SET_DATA_META(dataMeta)
          return response.data.data
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching admins' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          return []
        }
      },
      async exportUsersCSV() {
        try {
          appAdmins.mutations.SET_IS_LOADING(true)

          const queryParams = {
            sortBy: 'name',
            sortDesc: 0,
            role: 'admin',
          }

          const response = await exportAdmins(queryParams)
          if (response.status === 200) {
            downloadFile(response.data, 'Admins', 'csv')
          }
        } catch (error) {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error exporting admins',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        } finally {
          appAdmins.mutations.SET_IS_LOADING(false)
        }
      },
    },
  },
)

appAdmins.register(store)
